import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common';
import BadgeComponent from '@segment/matcha/components/Badge';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './CopyText.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var BaseOverline = function BaseOverline(_ref) {
  var tokens = _ref.tokens,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "overline" : _ref$variant,
      _ref$tag = _ref.tag,
      tag = _ref$tag === void 0 ? "p" : _ref$tag,
      children = _ref.children,
      externalAppearance = _ref.appearance,
      className = _ref.className;
  var appearance = useAppearance(externalAppearance);
  return _jsx(views.Overline, _objectSpread(_objectSpread({}, tokens), {}, {
    variant: variant,
    tag: tag,
    appearance: appearance,
    className: className,
    children: children
  }));
};

export var Overline = withDesignTokens(BaseOverline, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      color: theme.palette.brand.boysenberry[500]
    };
  },
  dark: function dark(theme) {
    return {
      color: theme.palette.brand.segmentGreen[500]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      color: theme.palette.twilio.green[60]
    };
  },
  twilioDark: function twilioDark(theme) {
    // eslint-disable-next-line no-warning-comments
    // TODO: Replace with twilioDark color
    return {
      color: theme.palette.twilio.green[60]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      color: theme.palette.cdpWeek2023.mint[100]
    };
  }
});
export var Heading = function Heading(_ref2) {
  var _ref2$variant = _ref2.variant,
      variant = _ref2$variant === void 0 ? "h2" : _ref2$variant,
      _ref2$tag = _ref2.tag,
      tag = _ref2$tag === void 0 ? "h3" : _ref2$tag,
      children = _ref2.children,
      externalAppearance = _ref2.appearance,
      className = _ref2.className,
      dangerouslySetInnerHTML = _ref2.dangerouslySetInnerHTML;
  var appearance = useAppearance(externalAppearance);
  return _jsx(views.Heading, {
    variant: variant,
    tag: tag,
    appearance: appearance,
    className: className,
    dangerouslySetInnerHTML: dangerouslySetInnerHTML,
    children: children
  });
};

var BaseDescription = function BaseDescription(_ref3) {
  var tokens = _ref3.tokens,
      _ref3$variant = _ref3.variant,
      variant = _ref3$variant === void 0 ? "pMedium" : _ref3$variant,
      _ref3$tag = _ref3.tag,
      tag = _ref3$tag === void 0 ? "h3" : _ref3$tag,
      children = _ref3.children,
      externalAppearance = _ref3.appearance,
      className = _ref3.className,
      dangerouslySetInnerHTML = _ref3.dangerouslySetInnerHTML;
  var appearance = useAppearance(externalAppearance);
  return _jsx(views.Description, _objectSpread(_objectSpread({}, tokens), {}, {
    variant: variant,
    tag: tag,
    appearance: appearance,
    className: className,
    dangerouslySetInnerHTML: dangerouslySetInnerHTML,
    children: children
  }));
};

export var Description = withDesignTokens(BaseDescription, function () {
  return {};
}, {
  light: function light(theme) {
    return {
      color: theme.palette.brand.darkAcai[500]
    };
  },
  dark: function dark(theme) {
    return {
      color: theme.palette.brand.darkAcai[400]
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      color: theme.palette.twilio.gray[100]
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      color: theme.palette.neutrals.white[100]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      color: theme.palette.twilio.gray[10]
    };
  }
});
export var Link = function Link(_ref4) {
  var href = _ref4.href,
      target = _ref4.target,
      color = _ref4.color,
      _ref4$size = _ref4.size,
      size = _ref4$size === void 0 ? "medium" : _ref4$size,
      children = _ref4.children,
      appearance = _ref4.appearance,
      className = _ref4.className;
  return _jsx(views.LinkOuter, {
    className: className,
    children: _jsx(views.LinkInner, {
      color: color,
      appearance: appearance,
      size: size,
      tag: "a",
      href: href,
      target: target,
      iconEnd: ChevronRightIcon,
      children: children
    })
  });
};
export var Badge = function Badge(_ref5) {
  var _ref5$variant = _ref5.variant,
      variant = _ref5$variant === void 0 ? "filledBlue" : _ref5$variant,
      children = _ref5.children;
  return _jsx(BadgeComponent, {
    variant: variant,
    children: children
  });
};